import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import {
  Header,
  Footer,
  Loader,
  DCXButton,
  Steps,
  Intro,
} from "../../components"
import { checkoutApprovedImage, checkoutRejectedImage } from "../../assets"
import { CarContext, UIContext } from "../../context"
import { createTransbank } from "../../api"
import { urlWithSession } from "../../utils"
import { useWithCredit } from "../../hooks"

export default function CheckoutInfo() {
  const { checkoutData, setCheckoutData, appLoading } = useContext(CarContext)
  const { openDemoAlert } = useContext(UIContext)
  const [withCredit] = useWithCredit()

  const primaryButtonText =
    checkoutData.preApproved || !withCredit ? "PAGAR RESERVA" : "VOLVER AL HOME"

  const goToTransbank = () => {
    if (checkoutData.leadUUID)
      createTransbank(checkoutData.leadUUID)
        .then(data => {
          setCheckoutData(prev => ({
            ...prev,
            transbankUrl: data.url,
            transbankToken: data.token,
          }))
          setLoading(false)
          document.getElementById("webpay-form").submit()
        })
        .catch(() => {
          setLoading(false)
          window.alert(
            "Hubo un problema procesando su reserva. Por favor intente más tarde."
          )
        })
  }

  const primaryButtonAction =
    checkoutData.preApproved || !withCredit
      ? openDemoAlert //goToTransbank
      : () => navigate(urlWithSession("/home"))

  // Loading while requesting transbank info
  const [loading, setLoading] = useState(true)

  // Navigate home if lead uuid not on context
  useEffect(() => {
    if (appLoading) return
    if (!checkoutData.leadUUID) navigate(urlWithSession("/home"))
    else setLoading(false)
  }, [checkoutData.leadUUID, appLoading])

  return (
    <>
      <Header />
      <div className="checkout-info standard-page">
        <div className="content">
          <div className="page-title">Próximos pasos</div>
          {withCredit && (
            <Intro
              image={RESULTS[String(Boolean(checkoutData.preApproved))].image}
              title={RESULTS[String(Boolean(checkoutData.preApproved))].title}
              subtitle={
                RESULTS[String(Boolean(checkoutData.preApproved))].subtitle
              }
            />
          )}
          <Steps
            completed={checkoutData.preApproved || !withCredit ? [1, 2] : [1]}
            pending={checkoutData.preApproved ? [] : [2]}
          />
        </div>
        <div className="buttons">
          <DCXButton
            type="secondary"
            text="ATRÁS"
            onClick={() => window.history.back()}
          />
          <DCXButton
            type="primary"
            text={primaryButtonText}
            onClick={primaryButtonAction}
          />
        </div>
      </div>
      <form id="webpay-form" action={checkoutData.transbankUrl} method="post">
        <input
          type="hidden"
          name="token_ws"
          value={checkoutData.transbankToken}
        />
      </form>
      <Footer />
      {loading && <Loader />}
    </>
  )
}

const RESULTS = {
  true: {
    image: checkoutApprovedImage,
    title:
      "Según los datos que ingresaste en la pre-evaluación eres sujeto a crédito",
    subtitle: "Reserva tu auto para continuar",
  },
  false: {
    image: checkoutRejectedImage,
    title: "Necesitamos saber un poco más",
    subtitle: "Uno de nuestros asesores te contactará en las próximas 24 horas",
  },
}
